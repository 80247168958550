<template>
  <div
    class="uploader"
    :class="{ completed: isComplete }"
  >
    <div
      id="dropzone"
      ref="dropzoneElement"
      class="dropzone"
    />
  </div>
</template>

<script>
import Dropzone from 'dropzone'

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    acceptedFileTypes: {
      type: String,
      default: '.png, .gif, .jpeg, .jpg',
    },
    uploadMultiple: {
      type: Boolean,
      default: false,
    },
    dictDefaultMessage: {
      type: String,
      default: 'Click to upload images',
    },
    timeout: {
      type: Number,
      default: 0,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    maxFilesize: {
      type: Number,
      default: 256,
    },
  },

  data() {
    return {
      dropzone: null,
      addedFile: null,
      isComplete: false,
    }
  },

  computed: {
    cloudIcon: function () {
      return '<i class="fa fa-cloud-upload" style="margin-right: 10px;"></i>'
    },
  },

  watch: {
    isDisable(value) {
      if (value) {
        this.$refs.dropzoneElement.style.pointerEvents = 'none'
      } else {
        this.$refs.dropzoneElement.style.pointerEvents = 'unset'
      }
    },
  },

  mounted() {
    const _self = this
    let options = {
      url: this.url,
      method: 'post',
      acceptedFiles: this.acceptedFileTypes,
      timeout: this.timeout * 1000,
      uploadMultiple: this.uploadMultiple,
      maxFilesize: this.maxFilesize,
      dictDefaultMessage: this.cloudIcon + this.dictDefaultMessage,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Accept: 'application/json',
        'Cache-Control': '',
        'X-Requested-With': '',
      },
      autoProcessQueue: true,
      maxFiles: 1,
      accept(file, done) {
        const type = _self.getFileType(file.type)
        if (type !== 'images') {
          file.previewElement.children[0].children[0].src = `/static/img/${type}.jpg`
        }
        _self.addedFile = file
        _self.$refs.dropzoneElement.style.pointerEvents = 'none'
        _self.$emit('uploadStart')
        done()
      },
      thumbnail(file, dataUrl) {
        file.previewElement.children[0].children[0].src = dataUrl
        _self.addedFile = file
      },
      success(file, response) {
        _self.isComplete = true
        _self.$emit('uploadComplete', response)
      },
      complete() {
        _self.$refs.dropzoneElement.style.pointerEvents = 'unset'
      },
    }

    this.dropzone = new Dropzone(this.$refs.dropzoneElement, options)

    this.dropzone.on('addedfile', () => {
      this.isComplete = false
      if (this.addedFile) {
        this.dropzone.removeFile(this.addedFile)
        this.addedFile = null
      }
    })
  },

  methods: {
    getFileType(type) {
      if (type.includes('image/')) {
        return 'image'
      } else if (type.includes('text/csv')) {
        return 'csv'
      }
      return 'unknown'
    },
  },
}
</script>

<style lang="scss">
@import "../../../node_modules/dropzone/dist/dropzone.css";

.uploader {
  &.completed {
    .dropzone {
      .dz-preview.dz-processing {
        .dz-progress {
          height: 35px;
          width: 35px;
          border-radius: 50%;
          background-color: #fff;
          border: 3px solid #ccb508;
          text-align: center;
          &:before {
            background-color: transparent;
            content: "\2713";
            color: #fd3f01;
            font-size: 24px;
            animation: none;
          }
        }
      }
    }
  }
  .dropzone {
    min-height: 100px;
    width: 100%;
    background: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
    &.dz-started {
      .dz-message {
        display: none !important;
      }
    }
    .dz-message {
      display: flex;
      font-size: 16px;
      color: #9ba9c4;
      margin: 20px 0px;

      svg {
        margin-right: 10px;
      }

      .media-upload-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h4 {
          font-weight: 300;
          margin-bottom: 10px;
        }

        h5 {
          font-weight: 300;
        }

        .icon {
          font-size: 20px;
          margin-bottom: 10px;
          margin-right: 0px;
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }

    .dropzone-loader {
      position: absolute;
      height: 122px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
      background: rgba(255, 255, 255, 0.8);
    }

    &.dz-drag-hover {
      background-color: #f8fafc;
    }
    .dz-preview.dz-processing {
      span {
        display: none !important;
      }
      .dz-progress,
      .dz-progress:before {
        width: 80px;
        margin: 0 auto;
        left: 0;
        right: 0;
        transition: none;
      }
      .dz-progress {
        background-color: #b3d4fc;
        display: -webkit-flex;
        display: flex;
      }
      .dz-progress:before {
        background-color: #3f51b5;
        content: "";
        -webkit-animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1)
          infinite;
        animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
      }
      @-webkit-keyframes running-progress {
        0% {
          margin-left: 0px;
          margin-right: 100%;
        }
        50% {
          margin-left: 25%;
          margin-right: 0%;
        }
        100% {
          margin-left: 100%;
          margin-right: 0;
        }
      }
      @keyframes running-progress {
        0% {
          margin-left: 0px;
          margin-right: 100%;
        }
        50% {
          margin-left: 25%;
          margin-right: 0%;
        }
        100% {
          margin-left: 100%;
          margin-right: 0;
        }
      }
    }
    .dz-preview.dz-error {
      .dz-progress {
        display: none !important;
      }
      .dz-error-mark {
        margin: -5px 0 0 -18px;
        z-index: 200;
        &:before {
          background-color: #fff;
          content: "✖";
          color: #fd3f01;
          font-size: 24px;
          animation: none;
          padding: 0 7px;
          border-radius: 50%;
          border: 3px solid #ccb508;
        }
        svg {
          display: none;
        }
      }
    }
    .dz-image {
      width: 95px;
      border-radius: 0;
    }
  }
}
</style>
